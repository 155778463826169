import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "homepage-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, traceSVG: { background: "#fff", color: "#000" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <Img
      fluid={data.heroImage.childImageSharp.fluid}
      className="w-full h-full top-0 left-0 object-cover"
      style={{
        position: "absolute",
        zIndex: -1,
      }}
    />
  );
};

export default HeroImage;
