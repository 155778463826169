import React from "react";
import NavLink from "./NavLink";
import logo from "../../images/distinct-logo-minimal.png";
import MobileNav from "./MobileNav";

const HomeNav = () => (
  <header className="pt-4 px-0 md:pt-8 relative">
    <div className="flex md:hidden mt-2 pr-4 justify-between">
      <img src={logo} alt="Distinct Performance logo" className="h-10 -mt-2" />
      <MobileNav />
    </div>

    <ul className="hidden md:flex items-center justify-center">
      <li className="md:pl-4">
        <NavLink variant="homeNav" link="/about-us">
          About us
        </NavLink>
      </li>
      <li>
        <NavLink variant="homeNav" link="/services">
          Services
        </NavLink>
      </li>
      <img src={logo} alt="Distinct Performance logo" className="relative px-10" />
      <li>
        <NavLink
          variant="homeNav"
          isExternalLink
          link="https://goteamup.com/p/3218555-distinct-performance/"
        >
          Schedule
        </NavLink>
      </li>
      <li>
        <NavLink variant="homeNav" link="/contact-us">
          Contact us
        </NavLink>
      </li>
    </ul>
  </header>
);

export default HomeNav;
