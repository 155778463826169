/**
 * Upsell Banner
 * This component is used throughout the site. It will
 * eventually need to be content managed.
 */
import React from "react";
import PropTypes from "prop-types";

import Button from "../button";
import Heading from "../heading";

const Banner = ({ title, subtitle, buttonText, link, buttonCallback }) => (
  <div className="bg-distinct-grey px-5 md:px-0 py-10 md:py-16 text-center text-white my-10 md:mt-16 md:mb-12">
    <Heading variant="h2" className="leading-snug mb-12 text-5xl md:text-7xl">
      {title}
    </Heading>
    {subtitle && (
      <div className="leading-snug m-auto max-w-lg">
        <p className="font-standard mb-12 text-xl md:text-3xl opacity-90">{subtitle}</p>
      </div>
    )}

    {link ? (
      <Button externalLink={link} variant="outlined">
        {buttonText}
      </Button>
    ) : (
      <Button onClick={buttonCallback} variant="outlined">
        {buttonText}
      </Button>
    )}
  </div>
);

Banner.propTypes = {
  buttonCallback: PropTypes.func,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

Banner.defaultProps = {
  title: "Ready to get started?",
  buttonCallback: null,
  buttonText: "Register",
  link: null,
  subtitle: null,
};

export default Banner;
