import React from "react";

import Image from "./image";
import Heading from "../../heading";
import HomeNav from "../../navigation/HomeNav";

const HeroHome = () => (
  <div className="flex flex-col text-white md:text-center relative pl-4 md:pl-0 md:pt-4 mb-6 md:mb-16 hero-home">
    <HomeNav />

    <div className="flex-grow flex items-center md:justify-center md:pb-16">
      <Heading className="text-5xl md:text-8xl pr-4 md:pr-0">
        <span className="block mb-8 md:mb-2 opacity-85 text-2xl md:text-4-1/2xl tracking-normal">
          Welcome to
        </span>
        Distinct Performance
      </Heading>
    </div>

    <Image />
  </div>
);
export default HeroHome;
