import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Heading from "../components/heading";
import MembershipCard from "../components/membership-card";

const Memberships = () => {
  // Static data queries for the card images...
  const images = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "card-one.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 426) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "card-two.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 426) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThree: file(relativePath: { eq: "card-three.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 426) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <section className="text-center md:mb-16">
      <Heading variant="h3" className="text-4-3/4xl leading-none md:text-7xl mb-10">
        Our Memberships
      </Heading>

      <p className="font-standard tracking-wide text-lg md:text-3xl leading-normal md:leading-tight max-w-5xl mx-auto mb-14">
        Join our community of like minded individuals, all chasing a common goal;
        increased health and fitness and with that, a fuller life
      </p>

      <div className="flex flex-col md:flex-row justify-around relative block">
        <MembershipCard
          imageUrl={images.imageOne}
          title="Distinct Base"
          body="Gym Membership and group based strength and conditioning program accessible to all"
          price={60}
          cardNumber={1}
          link="https://goteamup.com/p/3218555-distinct-performance/memberships/74304/"
        />

        <MembershipCard
          imageUrl={images.imageTwo}
          title="Auxiliary"
          body="Used in addition to Base.
        To give those who love training individual attention to reach their goals."
          price={90}
          cardNumber={2}
          link="https://goteamup.com/p/3218555-distinct-performance/memberships/74305/"
        />

        <MembershipCard
          imageUrl={images.imageThree}
          title="Individual"
          body="A Distinct Coach tailoring your programming based on your resources and goals."
          price={120}
          cardNumber={3}
          link="https://goteamup.com/p/3218555-distinct-performance/memberships/74306/"
        />
      </div>
    </section>
  );
};

export default Memberships;
