import React from "react";

import Layout from "../layouts";
import Banner from "../components/banner";
import HeroHome from "../components/heroes/home";
import Memberships from "../sections/memberships";
import QuoteBlock from "../components/quote-block";
import Heading from "../components/heading";

const IndexPage = () => (
  <Layout homepage title="Home">
    <HeroHome heroImageUrl="https://images.pexels.com/photos/791763/pexels-photo-791763.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" />
    <div className="flex flex-col md:flex-row items-center max-w-screen-desktop mx-auto px-4 md:px-6">
      <div className="mb-8 md:mb-0 md:w-1/2 md:pr-12">
        <QuoteBlock
          quoteText="Success is not final, failure is not fatal, it is the courage to continue that
  counts."
          caption="Winston Churchill"
        />
      </div>

      <div className="block md:hidden mb-6 w-full h-1/2 bg-distinct-grey-concrete" />

      <div className="leading-relaxed font-standard md:w-1/2 text-lg md:text-2xl md:pl-10">
        <Heading variant="h3" className="text-3xl md:text-4-1/2xl mb-4">
          About Us
        </Heading>

        <p className="mb-8">
          We believe fitness is about strengthening and conditioning our body and mind so
          you can get out there, explore the world and seek new adventures.
        </p>
        <p className="mb-8">
          We are not just for the &apos;elite&apos;. We are for anyone who wants to make a
          change, and is willing to put forward the effort required.
        </p>
        <p className="mb-8">
          We value hard work, commitment and consistency. Without all three you will
          always fall short of your full capability.
        </p>
        <p className="mb-8">
          Community is our foundation, as well as our compass. The power of the collective
          will carry you much further than if you go alone.
        </p>
        <p>Everything is earned. Nothing is given.</p>
      </div>
    </div>

    <Banner link="https://goteamup.com/p/3218555-distinct-performance/memberships/" />

    <div className="max-w-screen-desktop mx-auto px-6 md:px-6 md:pt-4">
      <Memberships />
    </div>
  </Layout>
);

export default IndexPage;
