import React from "react";
import PropTypes from "prop-types";

const Quoteblock = ({ quoteText, caption }) => (
  <blockquote>
    <p className="font-gobold leading-tight mb-6 md:mb-12 text-4xl md:text-6xl tracking-tight uppercase">
      {quoteText}
    </p>
    <footer>
      <p className="flex items-center font-mono text-lg md:text-2xl quote-block-caption">
        {caption}
      </p>
    </footer>
  </blockquote>
);

Quoteblock.propTypes = {
  quoteText: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};

export default Quoteblock;
