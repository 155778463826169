/**
 * Membership card component.
 * This is the membership card that is seen on the
 * homepage.
 */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "gatsby-image";

import Button from "../button";
import Heading from "../heading";

const MembershipCard = ({
  // altText,
  body,
  cardNumber,
  imageUrl,
  price,
  title,
  link,
  className,
}) => (
  <div
    className={classNames(
      "relative text-white flex-1 mb-8 md:mb-0 md:mr-14 last:mr-0",
      className,
      {
        "bg-black": !imageUrl,
      },
    )}
  >
    {imageUrl && (
      <Image
        fluid={imageUrl.childImageSharp.fluid}
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      />
    )}

    <div
      className="flex flex-col justify-between pb-10 pt-16 md:pt-20 px-4 text-center relative"
      style={imageUrl ? { background: "rgba(0, 0, 0, .5)" } : null}
    >
      {cardNumber && (
        <p className="opacity-80 membership-card-counter text-2xl">&#35;{cardNumber}</p>
      )}
      <Heading variant="h3" className="mb-4 md:mb-8 text-4-1/2xl md:text-5-1/2xl">
        {title}
      </Heading>
      <p className="font-standard text-lg md:text-2xl mb-6 md:mb-10 opacity-90">{body}</p>
      <p className="mb-4 md:mb-8 font-mono text-5xl md:text-5-1/2xl">
        £{price.toFixed(2)}
      </p>
      <div className="text-black mb-5">
        <Button externalLink={link} variant="primary">
          Register
        </Button>
      </div>
    </div>
  </div>
);

MembershipCard.propTypes = {
  cardNumber: PropTypes.number,
  imageUrl: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  price: PropTypes.number.isRequired,
  className: PropTypes.string,
};

MembershipCard.defaultProps = {
  cardNumber: null,
  imageUrl: null,
  link: null,
  body: null,
  className: null,
};
export default MembershipCard;
